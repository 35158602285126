const config = {
  siteTitle: "Chris Qiu", // Site title.
  keywords: ['Chris Qiu', 'Data engineer', 'Software engineer', 'Developer', 'Data science', 'Programming', 'Coder', 'Coding', 'Python'],
  siteTitleShort: "Chris Qiu", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: "Chris Qiu", // Alternative site title for SEO.
  authorAvatar: "/assets/images/codercat.jpg",
  authorName: "Chris Qiu",
  authorDescription: "I'm a polyglot engineer who can put on multiple hats ranging from data engineering, data science to backend and frontend development and mobile application development.",
  siteLogo: "/logos/logo-192.png", // Logo used for SEO and manifest.
  siteUrl: "https://chrisqiu.com", // Domain of your website without pathPrefix.
  pathPrefix: "", // Prefixes all links. For cases when deployed to example.github.io/gatsby-material-starter/.
  fixedFooter: false, // Whether the footer component is fixed, i.e. always visible
  siteDescription: "Chris Qiu Homepage", // Website description used for RSS feeds/meta description tag.
  siteRss: "/rss.xml", // Path to the RSS file.
  siteRssTitle: "Chris Qiu Homepage RSS feed", // Title of the RSS feed
  siteFBAppID: "", // FB Application ID for using app insights
  siteGATrackingID: "", // Tracking code ID for google analytics.
  disqusShortname: "", // Disqus shortname.
  dateFromFormat: "YYYY-MM-DD", // Date format used in the frontmatter.
  dateFormat: "DD/MM/YYYY", // Date format for display.
  userName: "Chris", // Username to display in the author segment.
  userEmail: "xxx", // Email used for RSS feed's author segment
  userTwitter: "", // Optionally renders "Follow Me" in the UserInfo segment.
  userLocation: "Internet", // User location to display in the author segment.
  userAvatar: "/assets/images/codercat.jpg", // User avatar to display in the author segment.
  userDescription:
    "I'm a polyglot engineer who can put on multiple hats ranging from data engineering, data science to backend and frontend development and mobile application development.", // User description to display in the author segment.
  // Links to social profiles/projects you want to display in the author segment/navigation bar.
  userLinks: [
    {
      label: "GitHub",
      url: "https://github.com/chrisqiqiu",
      iconClassName: "fa fa-github",
    },
    {
      label: "Linkedin",
      url: "https://au.linkedin.com/in/chris-qiu",
      iconClassName: "fa fa-linkedin",
    },
     
  ],
  copyright: "Copyright © 2020. Chris Qiu", // Copyright string for the footer of the website and RSS feed.

  social: {
    // twitter: "https://twitter.com/",
    linkedin: "https://au.linkedin.com/in/chris-qiu",
    github: "https://github.com/chrisqiqiu",
    // email: ""
  },
  headerLinks: [
    {
      label: 'Home',
      url: '/',
    }
    ,{
      label: 'Blogs',
      url: '/blogs',
    }
  ],

  skills: [
    {
      name: 'Python',
      level: 90
    },
    {
      name: 'Javascript',
      level: 70
    },
    {
      name: 'SQL',
      level: 90
    },
    {
      name: 'Amazon Web Services',
      level: 70
    },
    {
      name: 'Google Cloud Platform',
      level: 60
    },
    {
      name: 'Serverless Architecture',
      level: 60
    },
    {
      name: 'Flask',
      level: 60
    },
    {
      name: 'React',
      level: 50
    },
    {
      name: 'Data engineering',
      level: 90
    },
    {
      name: 'Mobile application',
      level: 50
    },
    {
      name: 'Natural language processing',
      level: 50
    },
    /* more skills here */
  ],
  jobs: [
    /* more jobs here */
    {
      company: "Nine Entertainment Co.",
      begin: {
        month: 'Jan',
        year: '2019'
      },
      duration: null,
      occupation: "Data engineer",
      description: "I am part of the data science team, responsible for the development and maintenance of data pipelines and data lake."
  
    },  {
      company: "Oneflare",
      begin: {
        month: 'Oct',
        year: '2017'
      },
      duration: "2 years",
      occupation: "Data engineer",
      description: "Responsible for the development and maintenance of data pipelines, data warehouse and web scraping"
  
    }, {
      company: "Equifax",
      begin: {
        month: 'Nov',
        year: '2016'
      },
      duration: "1 year",
      occupation: "Developer",
      description: "Development and maintenance of applications for the credit platform."
    }, {
      company: "Acxiom/LiveRamp",
      begin: {
        month: 'Mar',
        year: '2015'
      },
      duration: "1 year",
      occupation: "Full stack developer/Data developer",
      description: "Responsible for the implementation of IT request platform and campaign web forms. Also responsible for development and maintenance data pipelines."
  
    },
  ],

  siteCover: '/assets/images/cover.jpeg',
  background_color: '#ffffff',
  theme_color: '#030303',
  fontColor: "#000000cc",
  enableDarkmode: true, // If true, enables dark mode switch
  display: 'minimal-ui',
  icon: 'src/assets/gatsby-icon.png',
};

// Validate

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === "/") {
  config.pathPrefix = "";
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, "")}`;
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === "/")
  config.siteUrl = config.siteUrl.slice(0, -1);

// Make sure siteRss has a starting forward slash
if (config.siteRss && config.siteRss[0] !== "/")
  config.siteRss = `/${config.siteRss}`;

module.exports = config;
