import React from 'react';
import './about.scss';
import styled from 'styled-components';
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const About =  ({ className, title = 'about', text = '' }) => {
  const data = useStaticQuery(graphql`
    {
      imgData: file(relativePath: { eq: "polyglot.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1184) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  
  const imgData = data.imgData.childImageSharp.fluid

  return (
    <div className={className}>
      <h2>{title}</h2>
      <p dangerouslySetInnerHTML={{ __html: text }} className="desc" ></p>
      <Img fluid={imgData} alt="ployglot" />
    </div>
  )
}


export default styled(About)`
.desc {
  color: ${({ theme }) => theme.colors.fontColor};

}
`;